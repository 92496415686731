import React from "react"
import moment from 'moment'
import { FaChevronRight } from "react-icons/fa"

const Campaign = props => {
	// 東京の時刻ゾーンで現在時刻を取得
	const update = moment();
	// 日付を取得
	const day = update.date();

	let pg_point = ''
	if(props.item.txt.match(/パンパース\(PayPay[0-9]+ポイント\)/))
	{
		const point = props.item.txt.match(/パンパース\(PayPay([0-9]+)ポイント\)/)[1]
		pg_point = `パンパース(＋PayPay${point}㌽)`
	}

  return (
		<>
			{/* { props.item.shopId === 'rakuten' && props.item.href.match(/babycareshop/) && props.item.href.match(/ds20240010/) && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Fa5a6fba3a5ef2ce3927d5bcbdd524b933da6587af6157313%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					2000円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{/* { props.item.shopId === 'rakuten' && props.item.href.match(/babycareshop/) && props.item.href.match(/ds20240030/) && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Fe8ecdbdba0454c453034095f8b0ebdc2cbba70ba79c6c908%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					1,000円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.href.match(/babycareshop/) && props.item.href.match(/ds20240001/) && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F26043bbbc0c3d244f63a0b218305b4188f469a55e46d1f32%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					1,000円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('オムツ800') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Fa3d745c91d3e918f3318dd5e2ea7273ec5be92be2cd574bc%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					800円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('オムツ600') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FgetCoupon%3Fgetkey%3DUFNSQi04RVNKLU1IVkwtU1NTSQ--' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					600円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('家計2500') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F55503e2626d17523750072d897ef81bbf0111a72526b8df8%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					2500円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('マラ200クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fsupersale%2F20240904dsysc%2F200coupon%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					200円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('グーンクーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F3e46a9d32734386db380e4da106b41bcd0d3d6ad49c02c6b%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					25%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('メリーズ10クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F801ef880793535926049cd17bae0edb8a26208d98e720ad1%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					10%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('メリーズ20クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F1e8f8519e2770d0b58056d500c00f7932ae0148a39c3e824%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					20%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('メリーズ25クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Ff17f0ea3fa77cf0b46080612cec8a0d89d06c6883941871c%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					25%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('メリーズ300クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F22936559e5a5b5622e7203de5360c48e9247f9e0bcc2f092%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					300円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('メリーズ500クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F302176b6e263ea28560869c1266e4b0816aa8209b55307e0%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					500円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('メリーズ600クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F1f4e391726473a34b6bae04aeefcd7b3882340f1dc96b57f%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					600円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('メリーズ700クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Fe51d261bb79cc845d36a29035bf077ece00d5e60cf723e0d%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					700円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('900クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fgenre%2Fdaily%2Fcoupon%2F2406%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					900円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('ムーニー10クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Fb11be32147dce71b99c60bd8e3d716907e0e2d5bb0abc18b%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					10%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('ベビーザらス1000クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fwww.rakuten.co.jp%2Ftoysrus%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					1,000円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('ベビーザらス500クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fwww.rakuten.co.jp%2Ftoysrus%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					500円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('ベビーザらス100クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fwww.rakuten.co.jp%2Ftoysrus%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					100円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('babycareshop50クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F6e7fe9d5b316595955fb45ba2976e796aceec76a5f1e98d3%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					50円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('ベイシア15クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FgetCoupon%3Fgetkey%3DMUZUUy02WTlPLUFLQk8tS0hIUA--' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					15%OFFクーポン(8/24 20:00-23:59)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('アイリス100クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F7d17759267af446a26ae4e1fe22acfe62ee1be5f5ee27135%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					100円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('アイリス150クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F0a0beb08d22ff61884a8677c8ba6783686a8d0e782da13a6%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					150円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('アイリス400クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F604633736323e846522eefb918357fbaa0608151b9f0c866%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					400円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('メグミ5クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F73922727677b09b00f3a425992b63e4f48e9c11c0d066778%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					5%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('こどもラボ100クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Fa4340b33a22bf270fd31b83c37cbaccb176af61a11332669%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					100円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('セイムス5クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F032def412be448e89274b41b0c053e318ada63763b6230c2%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					5%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('ベイシア1000クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F86cf38812c30d7e3d7718b7a92260e78563375af56a19ddd%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					1,000円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('ベイシア200クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F339e05d9d2f60407474deeced5ba9f256aa13f17f40cc50c%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					200円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('レック5クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Fbf9d421798fae0d9cb60e827dd73a673e97428f5586ecffe%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					5%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('レック50クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FgetCoupon%3Fgetkey%3DWVBRUi1DT1ZaLVIxTzQtMVNJRA--' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					50%OFFクーポン(9/19 20:00-21:59 先着100名限定)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('パンパース5クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F3e14964f5e71989071bb7d13386a98bec7218fa5f96c38b2%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					5%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('パンパース10クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F8890221edf850ab496003308835cdfc64f995a71899a08c7%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					10%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('グーン10クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Faf0b93c51733c02f6d01fbac1e342270d637c345735dc359%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					10%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('2個購入で10%OFF') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2F24.rakuten.co.jp%2Fbaby%2Fspecial%2F6528_107164%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					10%OFFクーポン(2個購入)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('明治5個15OOFFクーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Fe1b697ee3bb1d62b6acf90f84d7f7996a18273c00507e5be%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					15%OFFクーポン(5個購入)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('5個購入で20%OFF') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F0f584b6a724e6e3cfc549c888c59901739a07b9bd0bf6c7a%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					20%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('ベビー館250') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2F24.rakuten.co.jp%2Fbaby%2Fcampaign%2Fikujinohi_coupon%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					250円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('ベビー館10') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F03f8f8d7adf83b5923553f96669477e7cd82dbe4e62dcb53%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					10%OFFクーポン(8/1限定)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('ベビー館15') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F7eaa61014d9a39dbde9008890388f4f4d4382aa7861b44ff%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					15%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('ポイント還元1') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fsuperdeal%2Fcampaign%2Frakutencard%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					スーパーDEAL＋1%㌽エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{/* { props.item.shopId === 'rakuten' && !props.item.txt.includes('マラソン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2Fmarathon%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					お買い物マラソン(10/4(金)20時～)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('マラソン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2Fmarathon%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						マラソンエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('開始4時間10%ポイント') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fsuperdeal%2Fcampaign%2Fpointback10%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						＋10%㌽バックエントリー(開始4時間限定)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('モバイル10%ポイント') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fsuperdeal%2Fcampaign%2Fmobiledeal%2F20241004%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						＋10%㌽バックエントリー(楽天モバイル限定)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('★') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fsupersale%2Fmobile%2Fpresale%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						スーパーSALE(先行セール)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('スーパーセール') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fsupersale%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						スーパーSALEエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('ワン10クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2Fwonderfulday%2Fbrand%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						10%OFFクーポン(おむつ)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('ワン15クーポン') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2Fwonderfulday%2Fbrand%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						15%OFFクーポン(日用品)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('ワンダフル') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2Fwonderfulday%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						ワンダフルデーエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{/* { props.item.shopId === 'rakuten' && props.item.txt.includes('育児の日') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F17ceaa9e71318cb4f679f6e3c3da7a44f9e54ed48e230a90%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						200円OFFクーポン(3個購入)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('ママ割(マラソン)') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Ffamily%2Fmarathon%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						ママ割エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('ママ割(スーパーセール)') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Ffamily%2Fsupersale%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						ママ割エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('39ショップ') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fguide%2Ffreeshippingline%2Fcampaign%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						39ショップエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('いちばの日') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Frank%2Fpoint%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					いちばの日エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('育児の日') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fbaby%2F192campaign%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						育児の日エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('0のつく日') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fcard%2Fpointday%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						0のつく日エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('5のつく日') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fcard%2Fpointday%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						5のつく日エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('日用品') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fgenre%2Fdaily%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						日用品エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('カテゴリー買いまわり') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fbrand%2Fcross-genre%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						3カテゴリーエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.shop.includes('★') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2Fmarathon%2F20240109vwsfn%2Fall2%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						16:00-23:59限定(P2倍)エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('勝ったら') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fsports%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						勝ったら倍エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('48時間限定') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2F2shop3%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						2ショップ購入エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('ゲリラ') && (
				<>
					{/* <a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2Fmarathon%2F20240919pgtyn%2Fall2%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}> */}
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2Fmarathon%2F20240919pgtyn%2Fpointdouble%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						ポイントアップエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{/* { props.item.shopId === 'rakuten' && props.item.shop.includes('ベビー館') && props.item.txt.includes('育児の日') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FgetCoupon%3Fgetkey%3DT05KRS1ZSE5WLVNEUVQtMEU0Rw--%26rt%3D200107030' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						250円OFFクーポン(3個以上)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{/* { props.item.shopId === 'rakuten' && props.item.txt.includes('育児の日') && (
				<>
					<a href='https://hb.afl.rakuten.co.jp/hgc/1234624c.d2a40d30.1234624d.00b6e9c6/?pc=https%3A%2F%2Fevent.rakuten.co.jp%2Fbaby%2F192campaign%2Fcoupon%2F&link_type=hybrid_url&ut=eyJwYWdlIjoidXJsIiwidHlwZSI6Imh5YnJpZF91cmwiLCJjb2wiOjF9' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						育児の日クーポン(最大1,000円OFF)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{/* { props.item.shopId === 'rakuten' && props.item.shop.includes('ベビー館') && props.item.txt.includes('育児の日') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FgetCoupon%3Fgetkey%3DTldMSy1OVFI0LU1ZVFktQlROVg--%26rt%3D200107030' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					育児の日クーポン(30%OFF)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{ props.item.shopId === 'rakuten' && props.item.txt.includes('★') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2Fmarathon%2F20240414thncc%2Fhistory%2F' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					リピート購入エントリー(＋1倍㌽)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'rakuten' && (day === 1) && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2Fwonderfulday%2Fhistory%2F%3Fl-id%3Dwonderfulday_pc_shop3_3' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					リピート購入エントリー(＋1倍㌽)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{/* { props.item.shopId === 'rakuten' && (day === 1) && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2Fwonderfulday%2Fcoupon%2F%3Fl-id%3Dwonderfulday_pc_shop3_4' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
					1,000円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{/* { props.item.shopId === 'rakuten' && props.item.shop.includes('') && (
				<>
					<a href='https://hb.afl.rakuten.co.jp/hsc/389ff0be.8226066f.15aa3532.80f53ac6/?link_type=hybrid_url&ut=eyJwYWdlIjoic2hvcCIsInR5cGUiOiJoeWJyaWRfdXJsIiwiY29sIjoxLCJjYXQiOjEsImJhbiI6MjM3Nzg5MiwiYW1wIjpmYWxzZX0%3D' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						楽天モバイル(＋4倍㌽)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{/* { props.item.shopId === 'rakuten' && props.item.shop.includes('') && (
				<>
					<a href='https://hb.afl.rakuten.co.jp/hsc/1f1e539d.d02e7b55.15aa3532.80f53ac6/?link_type=hybrid_url&ut=eyJwYWdlIjoic2hvcCIsInR5cGUiOiJoeWJyaWRfdXJsIiwiY29sIjoxLCJjYXQiOjEsImJhbiI6MTY2NzYzLCJhbXAiOmZhbHNlfQ%3D%3D' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						楽天カード(＋5000㌽)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('AEC69I7SLVTDK') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FAEC69I7SLVTDK' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						10%OFFクーポン(対象者限定)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('A3LTQDV6GEWDTN') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FA3LTQDV6GEWDTN' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						10%OFFクーポン(対象者限定)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('A3GLJ3S7WEJXWU') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FA3GLJ3S7WEJXWU' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						10%OFFクーポン(対象者限定)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('AY3U5PQXA0AC8') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FAY3U5PQXA0AC8' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						10%OFFクーポン(対象者限定)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}

			{ props.item.shopId === 'amazon' && props.item.txt.includes('A18384NVS0LP5C') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FA18384NVS0LP5C' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						5%OFFクーポン(対象者限定)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('A96A9U9Z6MM7R') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FA96A9U9Z6MM7R' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						5%OFFクーポン(対象者限定)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('A26J9T1IJ9BQ0Y') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FA26J9T1IJ9BQ0Y' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						5%OFFクーポン(対象者限定)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('A34CB6PH457H2X') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FA34CB6PH457H2X' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						5%OFFクーポン(対象者限定)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('A1LWYKL4U2HE1S') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FA1LWYKL4U2HE1S' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						5%OFFクーポン(対象者限定)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('A1GC3M5LZ0QM12') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FA1GC3M5LZ0QM12' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						5%OFFクーポン(対象者限定)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('A2T7ITYUWZ0TEV') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FA2T7ITYUWZ0TEV' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						5%OFFクーポン(対象者限定)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('A21Z1A8RT6VXL5') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FA21Z1A8RT6VXL5' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						5%OFFクーポン(対象者限定)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('GOONPLUS2441') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fb%3Fie%3DUTF8%26node%3D24578457051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						10%OFFクーポンコード&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('AF4000') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Ffmc%2Fm%2F30009285%2Fref%3Ds9_ot_cg_frsftrcp_1a1_w%3FalmBrandId%3DQW1hem9uIEZyZXNo' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						15%OFFクーポンコード(初利用 6,000円以上)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.shop.includes('フレッシュ') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2F-%2Fen%2Ffmc%2Fm%2F20200029%2F%3FalmBrandId%3DQW1hem9uIEZyZXNo' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						プライム会員(配送料490円)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('10%ポイント還元') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fgp%2Fbrowse.html%3Fnode%3D24578454051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						10%ポイント還元&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('エリエール') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FAD4YNO3RW9TPC' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						10%OFFまとめ割(エリエール2点)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('花王') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FA3U86CS615K1J5' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						10%OFFまとめ割(花王3点)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('新5') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fgp%2Fbrowse.html%3Fnode%3D17471078051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						5%OFFまとめ割(2点)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('まとめトク') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fgp%2Fbrowse.html%3Fnode%3D7958681051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						5%OFFまとめ割(まとめトク4点)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('母の日父の日') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fgp%2Fbrowse.html%3Fnode%3D19188185051%26field-enc-merchantbin%3DAN1VRQENFRJN5' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						5%OFFまとめ割(母の日･父の日2点)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('秋のまとめ買い') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fgp%2Fbrowse.html%3Fnode%3D21311306051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						5%OFFまとめ割(秋の2点まとめ買い)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('レックまとめ') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fgp%2Fbrowse.html%3Fnode%3D24818975051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						10%OFFまとめ割(レック2点)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('ベビー関連2点') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FA3ICNOXQ82K3KQ' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						5%OFFまとめ割(ベビー関連2点)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('Gakken') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fb%3Fie%3DUTF8%26node%3D24495196051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						5%OFF合わせ割(児童書)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('児童書') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fgp%2Fbrowse%3Fie%3DUTF8%26node%3D24562174051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						5%OFF合わせ割(児童書)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('花王森永乳業まとめ割り') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fb%3Fnode%3D24313663051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						5%OFFクーポン(森永乳業まとめ割)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('サントリー') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fgp%2Fbrowse.html%3Fnode%3D25902331051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						10%OFF(花王・サントリーまとめ買い)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('花王5千円以上千円引き') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FA3FWYHFVV636JV' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						1,000円OFF(花王 5,000円以上)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('花王3千円以上dポイント') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fgp%2Fbrowse.html%3Fnode%3D26286351051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						dポイント20%還元(花王 3,000円以上)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('PG5千円以上千円引き') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FA1I0630R4ZG2HY' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						1,000円OFF(P&G 5,000円以上)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.shop.includes('★') && (
				<>
					<a href='https://www.amazon.co.jp/events/hatsuuri?&linkCode=ll2&tag=gj7masawo00-22&linkId=e629753e730cd248d54b23074a197954&language=ja_JP&ref_=as_li_ss_tl' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						初売り(10%P還元)エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{/* { props.item.shopId === 'amazon' && props.item.txt.includes('Black Friday Sale') && (
				<>
					<a href='https://www.amazon.co.jp/events/hatsuuri?&linkCode=ll2&tag=gj7masawo00-22&linkId=e629753e730cd248d54b23074a197954&language=ja_JP&ref_=as_li_ss_tl' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						タイムセール祭りエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('★') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fevents%2Fmonthlydealevent' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						スマイルSALEエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('★') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fprimeday' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						プライムデーSALEエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('おやすみパンツ実質無料') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fs%3Frh%3Dn%253A24003587051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						おやすみパンツ実質無料&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('キュキュット実質無料') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fb%3Fie%3DUTF8%26node%3D24495191051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						キュキュット実質無料&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('除菌シート実質無料') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fgp%2Fbrowse.html%3Fnode%3D8416996051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						除菌シート実質無料&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('ダブルポイント') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fb%3Fie%3DUTF8%26node%3D10393976051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						ダブルポイント祭り&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.shop.includes('プライム') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fb%3Fie%3DUTF8%26node%3D6755653051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						定期おトク便&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{/* { props.item.shopId === 'amazon' && !props.item.shop.includes('プライム') && (
				<>
					<a href='https://www.amazon.co.jp/amazonprime?&linkCode=ll2&tag=gj7masawo00-22&linkId=2beaacf77b0ceb02650d4e6a80fca12d&language=ja_JP&ref_=as_li_ss_tl' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						プライム特典&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('らくベビ') && !props.item.txt.includes('らくベビ使用済') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fb%3Fnode%3D8489492051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						らくベビ 対象&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('ポイントアップキャンペーン(グーン)') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fb%2Fnode%3D24555596051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						らくベビ(リピート・サイズアップ)対象&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('ポイントアップキャンペーン(パンパース)') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fb%2Fnode%3D24555595051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						らくベビ(リピート・サイズアップ)対象&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('ポイントアップキャンペーン(メリーズ)') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fb%2Fnode%3D24555599051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						らくベビ(リピート・サイズアップ)対象&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && ((props.item.txt.includes('らくベビ') && !props.item.txt.includes('らくベビ使用済')) || props.item.txt.includes('ポイントアップキャンペーン')) && (
				<>
					<a href='https://www.amazon.co.jp/baby-reg/homepage?&linkCode=ll2&tag=gj7masawo00-22&linkId=d618f0ae3c5353925bbeaf279d4888f2&language=ja_JP&ref_=as_li_ss_tl' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						らくベビ 登録&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'amazon' && props.item.txt.includes('★') && (
				<>
					<a href='https://www.amazon.co.jp/b/ref_%253Dac_topban_fde?node=8514009051&linkCode=ll2&tag=gj7masawo00-22&linkId=8d7bac2624bc9694430b06d6820b2c03&language=ja_JP&ref_=as_li_ss_tl' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						ポイントアップキャンペーン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{/* { props.item.shopId === 'amazon' && props.item.txt.includes('') && (
				<>
					<a href='https://www.amazon.co.jp/gp/browse.html?node=8416996051&linkCode=ll2&tag=gj7masawo00-22&linkId=323033d16ebceacc03947787eab103de&language=ja_JP&ref_=as_li_ss_tl' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						今週の実質無料&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{/* { props.item.shopId === 'amazon' && props.item.txt.includes('出産準備') && (
				<>
					<a href='//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fb%3Fie%3DUTF8%26node%3D14248746051' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						出産準備(5%㌽還元)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{/* { props.item.shopId === 'amazon' && props.item.txt.includes('') && (
				<>
					<a href='https://www.amazon.co.jp/b/ref=adbl_JP_as_0068?ie=UTF8&node=5816607051&tag=gj7masawo00-22' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						聴く絵本(2ヶ月無料)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('OGM5ODNiYzVjNjJjNmFiZDczYmE3ZjI3YTIx') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/coupon/others/NjlhYTdhYTMwM2Y0NzgyMGQ4NGYzN2U0NzM3/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						20%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('Y2EyMGM0Njc0YTM1ZDk1YzE3YWJmZmI1M2Zh') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/coupon/others/NTVlZWNjMDdiMTAzNGIzMTk0YWU5NDE1NWFh/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						10%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('ODM0NTY4ZWVjNjc1OWU3N2JlMTY2N2ZlNGRj') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/coupon/others/ODM0NTY4ZWVjNjc1OWU3N2JlMTY2N2ZlNGRj/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						10%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('ゾロ目の日') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/coupon/zorome/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						3%OFFクーポン(ゾロ目の日)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('NzRhZDcyZWVjOTA2OGVmMDU3NDg1ZjE3NTBl') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/coupon/others/NzRhZDcyZWVjOTA2OGVmMDU3NDg1ZjE3NTBl/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						10%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('MzI2ZGZmM2ZiM2Y1YjIzMmVjNTVmM2FhNjY5') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/coupon/others/MzI2ZGZmM2ZiM2Y1YjIzMmVjNTVmM2FhNjY5')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						10%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('YTdmZmNhZGQxNmZiYTYwMGE4ZjY1M2U4N2Nk') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/coupon/others/YTdmZmNhZGQxNmZiYTYwMGE4ZjY1M2U4N2Nk/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						15%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('MjA0ZjNjMTM0ZGIyMDg1ZDM4NjE1YTJlOTli') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/coupon/others/MjA0ZjNjMTM0ZGIyMDg1ZDM4NjE1YTJlOTli/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						20%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('ZWY0MGQyNTNhZjExMmJhM2Y4YWE4MjkxZGYz') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/coupon/others/ZWY0MGQyNTNhZjExMmJhM2Y4YWE4MjkxZGYz/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						10%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('MTAwYzMzZWI4ZmEyMDU4NjRiZjM0MzA5MzIx') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/coupon/others/MTAwYzMzZWI4ZmEyMDU4NjRiZjM0MzA5MzIx/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						10%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('ストアラリー') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/storerally/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						ストアラリーエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('ボーナスストア') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/allbonus/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						ボーナスストアエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('ビッグボーナス') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/sale/bigbonus/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						ビッグボーナスエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('5の付く日') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/5day/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						5のつく日エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('LOHACO日曜日は誰でも') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/special/event/paypaycp_entry/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						日曜日は誰でもエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('P&G×yahoo') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/gateway/brand/pg/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						PayPay＋15% キャンペーン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{/* { props.item.shopId === 'lohaco' && props.item.txt.includes('エリエール') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/gateway/brand/daio_paper/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						エリエール＋10%㌽エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('買う！買う！サンデー') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/cowcow/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						買う！買う！サンデーエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('プレミアムな日曜日') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/lypsunday/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						プレミアムな日曜日エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('ハッピー24アワー') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/happyhour/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
					ハッピー24アワーエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('HAPPYDAY') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/special/event/happyday_entry/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						HAPPYDAYエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('ファーストデー') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/firstday/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
					ファーストデーエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'lohaco' && props.item.txt.includes('') && (
				<>
					<a href='//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=890207585' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						LYPプレミアム&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
					{/* <p style={{fontSize:`0.6rem`}}>&nbsp;&nbsp;&nbsp;※3/31まで1万円相当もらえるキャンペーン中<br />&nbsp;&nbsp;&nbsp;（特典が表示されない時は LINE ＞ 設定 ＞ LYPプレミアム）</p> */}
				</>
			)}
			{ props.item.shopId === 'aeon' && (day === 20 || day === 30) && (
				<>
					<a href={`https://click.linksynergy.com/deeplink?id=9Y6G27xjoU8&mid=45628&murl=${encodeURIComponent('https://aeonshop.com/pages/kansyaday')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#a3016f`, color: `#fff`}}>
						ポイント10倍&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'aupay' && props.item.txt.includes('コジマクーポン') && (
				<>
					<a href={`https://click.linksynergy.com/deeplink?id=9Y6G27xjoU8&mid=46164&murl=${encodeURIComponent('https://wowma.jp/bep/m/coup02?tf=couponInfo91&coupon_keys=%20ds_24_2_smpre_monthly_pobvsq%20&spe_id=prom686943931&spe_id=prom694441080')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#eb5505`, color: `#fff`}}>
						300円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'aupay' && props.item.txt.includes('倍々クーポン') && (
				<>
					<a href={`https://click.linksynergy.com/deeplink?id=9Y6G27xjoU8&mid=46164&murl=${encodeURIComponent('https://wowma.jp/event/multibuy/index.html')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#eb5505`, color: `#fff`}}>
						200円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'aupay' && props.item.txt.includes('ハッピークーポン') && (
				<>
					<a href='https://click.linksynergy.com/fs-bin/click?id=9Y6G27xjoU8&offerid=1122737.299&type=3&subid=0' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#eb5505`, color: `#fff`}}>
						1,000円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'aupay' && props.item.txt.includes('48時間限定') && (
				<>
					<a href={`https://click.linksynergy.com/deeplink?id=9Y6G27xjoU8&mid=46164&murl=${encodeURIComponent('https://wowma.jp/bep/m/coup02?tf=couponInfo80&coupon_keys=ds_24_02_spot_02_w4k')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#eb5505`, color: `#fff`}}>
						400円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'aupay' && props.item.txt.includes('三太郎の日') && (
				<>
					<a href='https://click.linksynergy.com/fs-bin/click?id=9Y6G27xjoU8&offerid=1122737.77&type=3&subid=0' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#eb5505`, color: `#fff`}}>
						三太郎の日エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{/* { props.item.shopId === 'aupay' && props.item.txt.includes('三太郎の日') && (
				<>
					<a href='//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=889211875' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#eb5505`, color: `#fff`}}>
						auスマプレ(30日無料/4,000円クーポン)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{/* { props.item.shopId === 'aupay' && !props.item.txt.includes('三太郎の日') && (
				<>
					<a href='//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=889211875' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#eb5505`, color: `#fff`}}>
						auスマプレ(4,000円クーポン)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{ props.item.shopId === 'aupay' && props.item.txt.includes('超超祭') && (
				<>
					<a href={`https://click.linksynergy.com/deeplink?id=9Y6G27xjoU8&mid=46164&murl=${encodeURIComponent('https://wowma.jp/event/cho2_festival/index.html')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#eb5505`, color: `#fff`}}>
						㌽超超祭エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'aupay' && props.item.txt.includes('ポイント倍々') && (
				<>
					<a href={`https://click.linksynergy.com/deeplink?id=9Y6G27xjoU8&mid=46164&murl=${encodeURIComponent('https://wowma.jp/event/multibuy/index.html')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#eb5505`, color: `#fff`}}>
						㌽倍々エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{/* { props.item.shopId === 'aupay' && props.item.shop.includes('auスマプレ') && ( */}
			{ props.item.shopId === 'aupay' && props.item.shop.includes('') && (
				<>
					<a href='//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=889211875' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#eb5505`, color: `#fff`}}>
						auスマプレ&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'yahoo' && props.item.txt.includes('ゾロ目の日') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/coupon/zorome/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff8400`, color: `#fff`}}>
						3%OFFクーポン(ゾロ目の日)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'yahoo' && props.item.txt.includes('週末クーポン') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/coupon/kaden/ZWYyZjhhMjIyODYyOWE3YWRiZGIyZjgyZjk5')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff8400`, color: `#fff`}}>
						100円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'yahoo' && props.item.txt.includes('メガストア10クーポン') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/coupon/others/YmQ5OWY1YjUwNDAxY2YxZTgwYTAwZjZjNjM2')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff8400`, color: `#fff`}}>
						10%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'yahoo' && props.item.txt.includes('アイリスプラザ10クーポン') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/coupon/baby/YTAxZjc3ZGRjYThhNDI3MGFhMjdmZTFkZmI0')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff8400`, color: `#fff`}}>
						10%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'yahoo' && props.item.txt.includes('10%OFFクーポン') && props.item.shop.includes('itsumo mart') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/coupon/interior/NDBmNjE1Nzc1YjE5YTdhMTE5YWUzZTgwOTRj')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff8400`, color: `#fff`}}>
						10%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'yahoo' && props.item.txt.includes('10%OFFクーポン') && props.item.shop.includes('ひかりTV') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/coupon/others/YjVlZjUxOTYwNDYwOWMyNGI5ZDc4YTMzMDJm')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff8400`, color: `#fff`}}>
						10%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'yahoo' && props.item.txt.includes('ストアラリー') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/storerally/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff8400`, color: `#fff`}}>
						ストアラリーエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'yahoo' && props.item.txt.includes('ボーナスストア') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/allbonus/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff8400`, color: `#fff`}}>
						ボーナスストアエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'yahoo' && props.item.txt.includes('ビッグボーナス') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/sale/bigbonus/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff8400`, color: `#fff`}}>
						ビッグボーナスエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'yahoo' && props.item.txt.includes('5の付く日') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/5day/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff8400`, color: `#fff`}}>
						5のつく日エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'yahoo' && props.item.txt.includes('P&G×yahoo') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/gateway/brand/pg/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff8400`, color: `#fff`}}>
						PayPay＋15% キャンペーン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'yahoo' && props.item.txt.includes('買う！買う！サンデー') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/cowcow/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff8400`, color: `#fff`}}>
						買う！買う！サンデーエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'yahoo' && props.item.txt.includes('プレミアムな日曜日') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/lypsunday/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff8400`, color: `#fff`}}>
						プレミアムな日曜日エントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'yahoo' && props.item.txt.includes('ハッピー24アワー') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/happyhour/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff8400`, color: `#fff`}}>
					ハッピー24アワーエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'yahoo' && props.item.txt.includes('ファーストデー') && (
				<>
					<a href={`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/firstday/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff8400`, color: `#fff`}}>
					ファーストデーエントリー&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'yahoo' && props.item.txt.includes('') && (
				<>
					<a href='//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=890207585' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff8400`, color: `#fff`}}>
						LYPプレミアム&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
					{/* <p style={{fontSize:`0.6rem`}}>&nbsp;&nbsp;&nbsp;※3/31まで1万円相当もらえるキャンペーン中<br />&nbsp;&nbsp;&nbsp;（特典が表示されない時は LINE ＞ 設定 ＞ LYPプレミアム）</p> */}
				</>
			)}
			{ props.item.shopId === 'akachan' && props.item.txt.includes('8のつく日') && (
				<>
					<a href={`https://ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884232649&vc_url=${encodeURIComponent('https://shop.akachan.jp/shop/r/rse90/#8happyday')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#e00012`, color: `#fff`}}>
						8のつく日(5倍㌽)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'akachan' && props.item.txt.includes('rgenki') && (
				<>
					<a href={`https://ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884232649&vc_url=${encodeURIComponent('https://shop.akachan.jp/shop/r/rgenki/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#e00012`, color: `#fff`}}>
						200円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'akachan' && props.item.txt.includes('rcpnmrs-1') && (
				<>
					<a href={`https://ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884232649&vc_url=${encodeURIComponent('https://shop.akachan.jp/shop/r/rcpnmrs-1/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#e00012`, color: `#fff`}}>
						100円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'akachan' && props.item.txt.includes('rcpnmrs-2') && (
				<>
					<a href={`https://ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884232649&vc_url=${encodeURIComponent('https://shop.akachan.jp/shop/r/rcpnmrs-2/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#e00012`, color: `#fff`}}>
						100円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'akachan' && props.item.txt.includes('rnmoony') && (
				<>
					<a href={`https://ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884232649&vc_url=${encodeURIComponent('https://shop.akachan.jp/shop/r/rnmoony')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#e00012`, color: `#fff`}}>
						100円OFFクーポン(おしりふき購入者)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'akachan' && props.item.txt.includes('9B7L-CRM3-TWB6') && (
				<>
					<a href={`https://ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884232649&vc_url=${encodeURIComponent('https://shop.akachan.jp/shop/e/ecpn-ol-a/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#e00012`, color: `#fff`}}>
						200円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'akachan' && props.item.txt.includes('8CYW-6L37-ZGTA') && (
				<>
					<a href={`https://ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884232649&vc_url=${encodeURIComponent('https://shop.akachan.jp/shop/e/ecpn-ol-a/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#e00012`, color: `#fff`}}>
						500円OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'babiesrus' && props.item.txt.includes('★') && (
				<>
					<a href={`https://click.linksynergy.com/deeplink?id=9Y6G27xjoU8&mid=2831&murl=${encodeURIComponent('https://www2.toysrus.co.jp/event/20311/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#5e2d91`, color: `#fff`}}>
						㌽10倍(8のつく日)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'babiesrus' && props.item.txt.includes('会員限定セール') && (
				<>
					<a href={`https://click.linksynergy.com/deeplink?id=9Y6G27xjoU8&mid=2831&murl=${encodeURIComponent('https://www.toysrus.co.jp/f/CSfSale_b.jsp')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#5e2d91`, color: `#fff`}}>
						会員限定セール&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'babiesrus' && props.item.txt.includes('ベビーザらスフェア') && (
				<>
					<a href={`https://click.linksynergy.com/deeplink?id=9Y6G27xjoU8&mid=2831&murl=${encodeURIComponent('https://www.toysrus.co.jp/f/CSfSale_b.jsp')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#5e2d91`, color: `#fff`}}>
						ベビーザらスフェア&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'tsuruha' && props.item.txt.includes('ツルハグループ') && (
				<>
					<a href='https://www.tsuruha.co.jp/campaign/HAPPAY_20240801/' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff0000`, color: `#fff`}}>
					30%㌽還元(HAPPAY×P＆G)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'tsuruha' && props.item.txt.includes('★') && (
				<>
					<a href='https://shop.tsuruha.co.jp/' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff0000`, color: `#fff`}}>
					全品送料無料・㌽7倍(8/7,8/8)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'tsuruha' && props.item.txt.includes('') && (
				<>
					<a href='https://shop.tsuruha.co.jp/t82-20240812-10off' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff0000`, color: `#fff`}}>
					全品10%OFFクーポン&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'kohnan' && props.item.txt.includes('楽天1000ポイント') && (
				<>
					<a href='https://www.pgjapanbrands.com/kohnan2409sib?JC=eshop' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff0000`, color: `#fff`}}>
					楽天1,000ポイント(P＆G5,000円以上)&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}

			{/* 以下P&Gキャンペーン */}
			{/* { props.item.shopId === 'rakuten' && props.item.txt.includes('P&G') && (
				<>
					<a href='https://pg-rtp-w3.spexperts.jp/national' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ad913d`, color: `#fff`}}>
						{pg_point}&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{/* { props.item.shopId === 'amazon' && props.item.txt.includes('P&G') && (
				<>
					<a href='https://pg-rtp-w3.spexperts.jp/national' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#473958`, color: `#fff`}}>
						{pg_point}&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{/* { props.item.shopId === 'lohaco' && props.item.txt.includes('P&G') && (
				<>
					<a href='https://pg-rtp-w3.spexperts.jp/national' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#998978`, color: `#fff`}}>
						{pg_point}&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{/* { props.item.shopId === 'aeon' && props.item.txt.includes('P&G') && (
				<>
					<a href='https://pg-rtp-w3.spexperts.jp/national' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#a3016f`, color: `#fff`}}>
						{pg_point}&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{/* { props.item.shopId === 'aupay' && props.item.txt.includes('P&G') && (
				<>
					<a href='https://pg-rtp-w3.spexperts.jp/national' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#eb5505`, color: `#fff`}}>
						{pg_point}&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{/* { props.item.shopId === 'yahoo' && props.item.txt.includes('P&G') && (
				<>
					<a href='https://pg-rtp-w3.spexperts.jp/national' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#ff8400`, color: `#fff`}}>
						{pg_point}&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
			{ props.item.shopId === 'akachan' && props.item.txt.includes('パンパース(PayPay') && (
				<>
					<a href={`https://ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884232649&vc_url=${encodeURIComponent('https://shop.akachan.jp/shop/r/rPGpayCP/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#e00012`, color: `#fff`}}>
						{pg_point}&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'nishimatsuya' && props.item.txt.includes('パンパース(PayPay') && (
				<>
					<a href='https://www.24028-net.jp/sc/pampers-paypay.html' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#e0a2b1`, color: `#fff`}}>
						{pg_point}&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'babiesrus' && props.item.txt.includes('パンパース(PayPay') && (
				<>
					<a href={`https://click.linksynergy.com/deeplink?id=9Y6G27xjoU8&mid=2831&murl=${encodeURIComponent('https://www2.toysrus.co.jp/event/19963/')}`} target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#5e2d91`, color: `#fff`}}>
						{pg_point}&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{ props.item.shopId === 'cainz' && props.item.txt.includes('パンパース(PayPay') && (
				<>
					<a href='https://www.pgjapanbrands.com/pampers202404amcp' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#256646`, color: `#fff`}}>
						{pg_point}&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)}
			{/* { props.item.shopId === 'kohnan' && props.item.txt.includes('P&G') && (
				<>
					<a href='https://pg-rtp-w3.spexperts.jp/national' target='_blank' rel='noopener noreferrer' class="button is-small is-rounded" style={{backgroundColor: `#005099`, color: `#fff`}}>
						{pg_point}&nbsp;&nbsp;&nbsp;
						<span className="icon">
							<FaChevronRight />
						</span>
					</a>
					&nbsp;
				</>
			)} */}
		</>
  )
}

export default Campaign
